* {
  /* font-family: 'Poppins', sans-serif; */

}

a{
  text-decoration: none !important;
}

.ladda-button {
  display: inline-block !important;
  position: static !important;
  margin-bottom: 0 !important;
  background: blue;
  color: white;
}

.drop-down-arrow {
  color: black;
}

.disabled-date-picker input {
  background-color: #e9ecef;
  /* Set the desired background color for the disabled field */
}

.show-pagination {
  width: 80px !important;
}

.required {
  color: red;
}

.list-invalid {
  border: 1px solid red;
  border-radius: 5px;
}

.custome-date .react-datepicker-wrapper {
  width: 80% !important;
}

.custom-table th {
  width: 25%;
}

.custom-table td {
  width: 75%;
}

.date-cursor:hover {
  cursor: pointer;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #23282c !important;
}

.nav-tabs .nav-link {
  color: #171b1d !important;
}

.react-read-more-read-less {
  color: #20a8d8;
}

.react-read-more-read-less-more {
  color: #20a8d8;
}

.capital:first-letter {
  text-transform: capitalize;
}

.business_about a {
  color: black;
  text-decoration: none;
  margin: 0px 4px;
}

.business_about a:hover {
  color: #20a8d8 !important;
  text-decoration: none;
}

.login-card {
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px !important;
  background-color: #FFD02B !important;
}

.card-background {
  background: #FFD02B !important;
}

.sidebar {
  background: rgba(125, 249, 255)
}

.themeColor {
  /* background-image: url("./assets/images/Group.png"); */
  background: #FFD02B;
  background-color: #FFD02B;
  /* background-image: linear-gradient(90deg, rgba(247, 213, 182, 1) 0%, rgba(221, 209, 187, 1) 51%, rgb(206, 224, 219) 100%) !important */
}

.bg-image {
  /* background-image: url("./assets/images/background-img.png");
  /* background-size: 100% ; */
  /* background-size: cover;  */
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("./assets/images/Group.png");
  ;
  /* background: linear-gradient(0deg, rgba(95, 158, 160) 20%, rgba(125, 249, 255) 77%); */
}

.logo {
  color: white;
  font-family: 'Comic Neue', cursive ;
  vertical-align: middle ;
  font-size: 2rem;
  font-weight: 600;
 
}


.logo:hover{
   color: white;
}

.round {
  border-radius: 50%;
}

.btn-course{
  font-size: 1.2rem !important;
  font-weight: 550 !important;
}

.delete-icon {
  color: red !important;
}

.imageCard{
  background-color:  #dee2e6;
}

.custom-input-style .rdrInput {
  width: 300px;
}

.loader-size {
  display: flex !important;
  justify-content: center !important;
  padding: 40px 0px !important;
}

.trans-background {
  /* background: white !important; */
  /* border: 0 !important; */
  /* padding: 50px 60px !important; */
  border-radius: 20px !important;
}

.new-font {
  font-family: 'Comic Neue', cursive !important;
  font-family: 'Poppins', sans-serif !important;
}

.date-time-picker {
  width: 100%;
  border: 1px solid #c5c6c7 !important;
  padding: 5px 15px !important;
  border-radius: 7px !important;
}

.date-time-picker-error {
  width: 100%;
  border: 1px solid #dc3545 !important;
  padding: 5px 15px !important;
  border-radius: 7px !important;
}

.date-time-message {
  color: #dc3545 !important;
  font-size: 14px !important;
}

.form-validation {
  width: 100%;
  border: 1px solid #dc3545 !important;
  padding: 5px 15px !important;
  border-radius: 7px !important;
}

.form-validation-message {
  color: #dc3545 !important;
  font-size: 14px !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.swal-footer {
  text-align: center;
}

.notFound{
  width: fit-content !important;
  background-color: aliceblue;
  border-radius: 50%;
  padding: 2%;
}

.notFoundh2{
   color:#dc3545;
   border-radius: 50%;
   font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
   font-weight: bolder;
   text-align: center;
   
}

.notFoundh4{
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  font-weight: bolder;
}

.notFoundText{
  font-size: 1.2rem;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}


button.btn.btn-close:hover {
  color: #000 !important;
  background: transparent url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23000%27%3e%3cpath d=%27M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z%27/%3e%3c/svg%3e") center/1em auto no-repeat !important;
}

button.btn.btn-secondary:hover{
  background: white !important;
  border-color: black !important;
}

button.btn.btn-dark:hover{
  background: white !important;
  color: black !important;
}

button.btn.btn-dark:hover{
  background-color: white !important;
  color: black !important;
}

button.btn.btn-danger:hover{
  background-color: white !important;
  color: black !important;
  border: 1px solid red;
}

.btn-primary:active,
.btn-primary:visited{
  background: white !important;
  color: #000;
}


.btn-outline-primary {
  color: rgba(125, 249, 255) !important;
  border-color: rgba(125, 249, 255) !important;
}

.btn-outline-primary .btn-outline-primary:hover .btn-outline-primary:visited .btn-outline-primary:active {
  background-color: linear-gradient(0deg, rgba(95, 158, 160) 20%, rgba(125, 249, 255) 77%) !important;
  color: black !important;
}

.btn:hover {
  color: black !important;
  background: rgba(125, 249, 255) !important;
}

.custom-modal .close:hover {
  color: rgb(0, 0, 0) !important;
  /* Replace "red" with your desired hover color */
}

.stickeredit {
  width: fit-content;
  padding: 1rem;
  margin: 1rem;
  justify-content: center;
}

/* .sidebar-nav .nav-link.active .nav-icon {
  color: rgb(0 0 0 / 87%);
} */

.sidebar-nav .nav-link.active {
  color: rgba(255, 255, 255, 0.87) !important;
  background: rgba(95, 158, 160) !important;
}

.sidebar .sidebar-nav .nav-icons{
  margin-right: 1.25rem;
  margin-left: 0.5rem;
}

.sidebar .sidebar-nav:hover {
  color: #171b1d !important;
}

.sidebar-nav .nav-link{
  font-weight: 400;
}

.sidebar-nav .nav-link:hover{
  color: #4446b9 !important;
  font-weight: bolder;
  font-size: 14;
}


.sidebar-nav svg.nav-icon {
  /* overflow: hidden; */
  color: #171b1d;
}

.sidebar-nav .nav-link {
  color: #171b1d;
}

.p-datatable{
  font-family: sans-serif;
  color: #000;
  font-size: 1rem;
}

.p-datatable .p-datatable-thead > tr {
  background: white !important;
}


tr{
 padding-block: 1% !important;
}

.container {
  width: 100%;
  max-width: 800px;
  margin: auto;
}

.list {
  border: 1px solid #fff;
  padding: 5px;
}

.pagination {
  display: flex;
  border: 1px solid #fff;
  justify-content: space-between;
  padding: 20px;
}

.pagination button {
  padding: 10px;
}

.active {
  background: black;
  color: #fff;
  border: 1px solid #fff;
}

.blankPageImage{
  width: "150px";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.imagetext{
  font-size: 1.5rem;
}

.blanktext{
  color:#20a8d8;
}